import React, { CSSProperties, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  text-decoration-thickness: 1.5px;
  text-underline-offset: -0.275em;
  text-decoration-skip-ink: none;
`

export const Crossable = ({
  children,
  style = {},
}: {
  children: React.ReactNode
  style?: CSSProperties
}) => {
  const [crossed, setCrossed] = useState(false)

  return (
    <Wrapper
      onClick={() => setCrossed(!crossed)}
      style={{
        cursor: 'pointer',
        color: crossed ? '#1826425e' : 'unset',
        textDecorationLine: crossed ? 'underline' : 'none',
        ...style,
      }}
    >
      {children}
    </Wrapper>
  )
}
