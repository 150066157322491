import React, { useEffect } from 'react'
import { Alert, Button, Form, Input } from 'antd'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import './Search.css'
import { IconButton } from '../components/IconButton'
import { formatTime } from '../data'
import { searchRecipes, setSearch, useSearch } from '../state'
import { ReactComponent as Stew } from '../assets/stew.svg'
import { ReactComponent as Logo } from '../assets/logo.svg'

const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 90px;
`

const Result = styled.div`
  display: block;
  background: #f1f4f3;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 10px;
  cursor: pointer;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background: #ccf0e3;
  }
`

const RecipeTitle = styled.div`
  font-weight: 700;
  font-size: 0.9em;
  line-height: 1em;
  font-family: Montserrat;
  margin-top: 4px;

  div {
    display: inline-block;
  }
`

const Tags = styled.div`
  font-size: 0.9em;
  margin-top: 5px;
`

const Time = styled.div`
  display: block;
  font-size: 0.8em;

  path {
    stroke-width: 2px !important;
  }
`

const Create = styled.div`
  text-align: center;

  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);

  z-index: 1000;
`
const Author = styled.div`
  opacity: 0.5;
  font-family: 'Montserrat';
  font-size: 0.85em;
  font-weight: 500;
  margin-left: 4px;

  span {
    font-weight: 700;
  }
`

export const Search = () => {
  const { search, found, loading, error } = useSearch()
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()

  const onSearch = () => {
    if (search) {
      setParams({ s: search }, { replace: true })
      searchRecipes(search)
    }
  }

  useEffect(() => {
    document.title = "pot's luck"

    const s = params.get('s')
    searchRecipes(s || undefined)
  }, [params])

  if (error?.message === 'Unauthorized') navigate('/login')

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '60px',
        }}
      >
        <Logo style={{ width: '100px' }} />
        <Stew style={{ maxWidth: '180px' }} />
      </div>

      <Form layout="vertical" style={{ marginTop: '60px' }}>
        {/* <p
          style={{
            textAlign: 'center',
            fontWeight: 700,
            fontFamily: 'Averia Serif Libre',
            fontSize: '1.4em',
          }}
        >
          Search for recipes...
        </p> */}
        <Form.Item>
          <Input
            size="large"
            value={search || ''}
            onChange={({ target: { value } }) => setSearch(value)}
            placeholder="Search for recipes..."
            allowClear={{
              clearIcon: (
                <Icon
                  icon="mynaui:x-square"
                  height="24px"
                  style={{ padding: '2px 0px 0px', marginTop: '3px' }}
                />
              ),
            }}
            disabled={loading}
            onPressEnter={onSearch}
            autoCapitalize="off"
            style={{
              width: '100%',
              fontSize: '15px',
            }}
            suffix={
              <Button
                type="text"
                size="small"
                onClick={onSearch}
                disabled={loading}
              >
                <Icon
                  icon={
                    loading ? 'line-md:loading-twotone-loop' : 'mynaui:search'
                  }
                  height="24px"
                  style={{ padding: '0 0 4px 0' }}
                />
              </Button>
            }
          />
        </Form.Item>
      </Form>

      {error != null && (found == null || found.length === 0) && (
        <Alert
          message="Aaand you broke it..."
          description={
            <p>
              You had to enter some text and then press <i>Enter</i> or the
              little search icon, and somehow, you managed to fuck that up... 🤦‍♂️
            </p>
          }
          type="error"
          showIcon
          icon={<Icon icon="solar:confounded-square-linear" />}
          style={{ marginBottom: '10px' }}
        />
      )}

      {!loading && found != null && found.length === 0 && search && (
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <Icon
            icon="healthicons:rdt-result-out-stock"
            style={{ fontSize: '3em', opacity: 0.4 }}
          />
          <p
            style={{
              marginTop: '0px',
              fontFamily: 'Montserrat',
              fontWeight: 600,
              opacity: 0.4,
              fontSize: '13px',
            }}
          >
            No results
          </p>
        </div>
      )}

      <div style={loading ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
        {(found || []).map(
          ({
            id,
            title,
            cookTime = 0,
            prepTime = 0,
            restingTime = 0,
            tags,
            author,
          }) => {
            const time = formatTime(cookTime + prepTime + restingTime)

            return (
              <Link to={`/${id}`} key={id}>
                <Result>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      gap: '7px',
                    }}
                  >
                    <RecipeTitle>
                      <div>{title}</div>

                      <Author>
                        <Icon
                          icon="solar:chef-hat-minimalistic-linear"
                          height="1.5em"
                          style={{ verticalAlign: 'sub' }}
                        />{' '}
                        <span>{author}</span>
                      </Author>
                    </RecipeTitle>
                    {time != null && (
                      <Time>
                        <Icon
                          icon="solar:clock-square-linear"
                          height="16px"
                          style={{ marginBottom: '-4px' }}
                        />{' '}
                        {time}
                      </Time>
                    )}
                  </div>

                  <Tags>{tags.join(', ')}</Tags>
                </Result>
              </Link>
            )
          }
        )}
      </div>

      <Create>
        <Link to="/new">
          <IconButton type="primary" icon="mynaui:file-plus" />
        </Link>
      </Create>
    </Wrapper>
  )
}
