import React from 'react'
import { Button } from 'antd'
import { Icon } from '@iconify/react'

export const IconButton = ({
  type = 'text',
  icon,
  onClick,
  disabled = false,
  style,
}: {
  icon: string
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
  type?: 'text' | 'primary'
  disabled?: boolean
  style?: React.CSSProperties
}) => (
  <Button
    disabled={disabled}
    type={type}
    style={{
      height: '45px',
      padding: '7.5px',
      ...style,
    }}
    onClick={onClick}
  >
    <Icon icon={icon} style={{ fontSize: '2em' }} />
  </Button>
)
