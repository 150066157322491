import React, { useEffect, useRef } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import * as R from 'ramda'

import './RecipeDraft.css'
import { Recipe, User, units } from '../data'
import { Nav } from './Nav'

const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 90px;
`

const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 11px !important;
  margin-top: 20px;
`

const SelectOption = styled.div`
  font-family: 'Montserrat';
  font-size: 0.85em;
  line-height: 1.8em;
  font-weight: 600;
  color: #182642;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  button.ant-btn-text {
    background: white;
    border: 1px solid #d9d9d9;
  }
`

const Optional = styled.span`
  opacity: 0.5;
  margin-top: auto;
  margin-left: 5px;
`

const Sort = styled.div`
  color: #182642;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  opacity: 0.8;

  & > div {
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    flex: 50%;
    height: 20px;
    padding: 3px;
    cursor: pointer;

    display: flex;

    &:hover {
      opacity: 0.5;
    }

    .iconify path {
      stroke-width: 2.5;
    }
  }
`

export const RecipeDraft = ({
  user,
  recipe,
  availableTags = [],
  loading = false,
  tagsLoading = false,
  onSave = () => {},
  onCancel = () => {},
  onDelete,
  loadingError,
  mutationError,
}: {
  user: User
  recipe?: Recipe
  availableTags?: string[]
  loading?: boolean
  tagsLoading?: boolean
  onSave?: (recipe: Recipe) => any
  onCancel?: () => any
  onDelete?: () => any
  loadingError?: Error
  mutationError?: Error
}) => {
  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()

  const confirm = () => {
    modal.confirm({
      title: 'Are you sure?',
      icon: <Icon icon="mynaui:triangle-danger" height={24} />,
      content: (
        <>
          <p>
            Like, really sure? If you delete it, that&apos;s the end of it, the
            recipe is gone forever and you can&apos;t retrieve it.
          </p>
          <p style={{ opacity: 0.8 }}>
            Matter of fact, why are you deleting this? Did you create it by
            mistake? Do you have a fancy paper version that&apos;s somehow a
            better experience than this? Are you worried about the reselling of
            your personal data? Maybe you didn&apos;t understand that a trash
            can meant &quot;delete&quot;? Are you stupid?
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.5em',
              marginTop: '20px',
              marginBottom: '-10px',
            }}
          >
            <Icon style={{ position: 'initial' }} icon="noto:woozy-face" />
          </p>
        </>
      ),
      okText: 'Delete it, I am stupid',
      cancelText: 'Never mind',
      onOk: onDelete,
      okButtonProps: { size: 'large', danger: true },
      cancelButtonProps: { size: 'large' },
      maskClosable: true,
    })
  }

  const mutationErrorRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (!loading) form.resetFields()

    if (mutationError != null)
      mutationErrorRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  }, [form, loading, mutationError, recipe])

  return (
    <Wrapper>
      <Nav />

      {loadingError != null && (
        <Alert
          message="OUCH!"
          description={
            <p>
              Alright move along. We&apos;re having issues loading the recipe
              data here. Don&apos;t ask too many questions, just try and retain
              what you wanted to do in your little brain and then come back
              later. You think you can do that? Great.
            </p>
          }
          type="error"
          showIcon
          icon={<Icon icon="solar:confounded-square-linear" />}
          style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'left' }}
        />
      )}

      <Form
        layout="vertical"
        form={form}
        initialValues={recipe}
        disabled={loading || loadingError != null}
        requiredMark={(label, { required }) =>
          required ? (
            <>{label}</>
          ) : (
            <>
              {label} <Optional>(optional)</Optional>
            </>
          )
        }
      >
        <Form.Item
          label={<Label>Title</Label>}
          name="title"
          validateDebounce={500}
          rules={[
            { required: true, message: 'Ever seen a recipe without a title?' },
            {
              min: 3,
              message:
                "No that's too short, try a title with more than 3 characters",
            },
            { max: 30, message: "No that's way too loooooong" },
            { whitespace: true, message: 'Only whitespace, great title!' },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label={<Label>Tags</Label>}
          name="tags"
          validateDebounce={500}
          rules={[
            {
              type: 'array',
              max: 10,
              message:
                "Ok, let's take it easy with the tags, you don't need more than 10",
            },
          ]}
        >
          <Select
            mode="tags"
            loading={tagsLoading}
            size="large"
            style={{ fontWeight: 700 }}
            options={availableTags.map((t) => ({ label: t, value: t }))}
            optionRender={(option) => (
              <SelectOption>{option.label}</SelectOption>
            )}
            suffixIcon={
              tagsLoading ? undefined : <Icon icon="mynaui:chevron-down" />
            }
            menuItemSelectedIcon={<></>}
            removeIcon={<Icon icon="mynaui:x" height="11px" />}
          />
        </Form.Item>

        <Form.Item label={<Label>Serves</Label>} style={{ width: '100%' }}>
          <Space.Compact style={{ width: '100%' }}>
            <Form.Item
              noStyle
              name="serveQuantity"
              normalize={(value) => {
                if (!value || form.getFieldValue('serveUnit')) return value
                form.setFieldValue('serveUnit', 'servings')
                return value
              }}
              rules={[
                {
                  message: 'You said how many?',
                  validator: (_, value) => {
                    const serveUnit = form.getFieldValue('serveUnit')
                    if (value == null && serveUnit != null && serveUnit !== '')
                      return Promise.reject()
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <InputNumber
                inputMode="numeric"
                size="large"
                placeholder="n"
                min={1}
                max={100}
              />
            </Form.Item>

            <Form.Item
              noStyle
              name="serveUnit"
              validateDebounce={500}
              rules={[
                {
                  min: 1,
                  message: "No that's too short",
                },
                { max: 40, message: "No that's too long, come on" },
                { whitespace: true, message: 'Please use letters?' },
              ]}
            >
              <Input
                size="large"
                name="serveUnit"
                autoCapitalize="off"
                placeholder="servings, crepes, persons, loaves, etc."
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>

        <Row gutter={10}>
          <Col sm={8} xs={24}>
            <Form.Item
              label={<Label>Prep</Label>}
              name="prepTime"
              validateDebounce={500}
              rules={[
                {
                  type: 'number',
                  max: 60 * 24,
                  message:
                    "More than a day's worth of preparation?! What are we making?!",
                },
              ]}
            >
              <InputNumber
                inputMode="numeric"
                style={{ width: '100%' }}
                size="large"
                addonAfter="min"
                min={5}
                step={5}
              />
            </Form.Item>
          </Col>

          <Col sm={8} xs={24}>
            <Form.Item
              label={<Label>Cook</Label>}
              name="cookTime"
              validateDebounce={500}
              rules={[
                {
                  type: 'number',
                  max: 60 * 24,
                  message:
                    "More than a day's worth of cooking?! We're making charcoal?",
                },
              ]}
            >
              <InputNumber
                inputMode="numeric"
                style={{ width: '100%' }}
                size="large"
                addonAfter="min"
                min={5}
                step={5}
              />
            </Form.Item>
          </Col>

          <Col sm={8} xs={24}>
            <Form.Item
              label={<Label>Resting</Label>}
              name="restingTime"
              validateDebounce={500}
              rules={[
                {
                  type: 'number',
                  max: 60 * 24 * 7,
                  message:
                    'It needs to rest for more than a week?! Stop screwing around',
                },
              ]}
            >
              <InputNumber
                inputMode="numeric"
                style={{ width: '100%' }}
                size="large"
                addonAfter="min"
                min={5}
                step={5}
              />
            </Form.Item>
          </Col>
        </Row>

        <Label style={{ marginBottom: '8px', display: 'block' }}>
          Ingredients
        </Label>

        <Form.List
          name="ingredients"
          rules={[
            {
              validator: (_, value) => {
                if (value == null || value.length === 0) return Promise.reject()
                return Promise.resolve()
              },
              message: 'No ingredients? Nice! Void for dinner!',
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div>
              {fields.map(({ key, name }, index) => (
                <div
                  key={key}
                  style={{ display: 'flex', marginBottom: 8, width: '100%' }}
                >
                  <div style={{ width: '100%' }}>
                    <div
                      style={{ display: 'flex', alignItems: 'first baseline' }}
                    >
                      <Sort style={{ position: 'relative', top: '-5px' }}>
                        <div
                          onClick={() => move(index, index - 1)}
                          aria-hidden="true"
                        >
                          <Icon icon="solar:list-arrow-up-minimalistic-line-duotone" />
                        </div>
                        <div
                          onClick={() => move(index, index + 1)}
                          aria-hidden="true"
                          style={{ alignItems: 'end' }}
                        >
                          {' '}
                          <Icon icon="solar:list-arrow-down-minimalistic-line-duotone" />
                        </div>
                      </Sort>
                      <Space.Compact size="large" style={{ width: '100%' }}>
                        <Form.Item
                          name={[name, 'quantity']}
                          rules={[
                            {
                              message:
                                'If you specify a unit, you must also specify a quantity',
                              validator: (_, value) => {
                                const unit = form.getFieldValue([
                                  'ingredients',
                                  name,
                                  'unit',
                                ])

                                if (
                                  value == null &&
                                  unit != null &&
                                  unit !== ''
                                )
                                  return Promise.reject()
                                return Promise.resolve()
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            inputMode="numeric"
                            size="large"
                            style={{ width: '80px' }}
                            min={0.25}
                          />
                        </Form.Item>

                        <Form.Item name={[name, 'unit']}>
                          <Select
                            placeholder="unit"
                            size="large"
                            options={[
                              {
                                label: '',
                                value: '',
                              },
                            ].concat(
                              units.map((u) => ({
                                value: u as string,
                                label: u as string,
                              }))
                            )}
                            rootClassName="unit-select"
                            style={{ width: '80px' }}
                            suffixIcon={<Icon icon="mynaui:chevron-down" />}
                          />
                        </Form.Item>

                        <Form.Item
                          className="ingredient-name"
                          name={[name, 'name']}
                          validateDebounce={500}
                          style={{ width: '100%' }}
                          rules={[
                            {
                              required: true,
                              message: 'Ingredients need names duh',
                            },
                            {
                              min: 1,
                              message: "No that's too short",
                            },
                            { max: 40, message: "No that's too long, come on" },
                            {
                              whitespace: true,
                              message: 'Please use letters?',
                            },
                          ]}
                        >
                          <Input
                            autoCapitalize="off"
                            size="large"
                            placeholder="flour, salt, etc."
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Space.Compact>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => remove(name)}
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                        <Icon
                          className="minus"
                          icon="mynaui:minus"
                          height="9px"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <Button
                size="large"
                onClick={() => add()}
                style={{ width: '100%' }}
              >
                Add ingredient
              </Button>
              <Form.ErrorList errors={errors} />
            </div>
          )}
        </Form.List>

        <Label style={{ margin: '30px 0 8px 0', display: 'block' }}>
          Instructions
        </Label>

        <Form.List
          name="steps"
          rules={[
            {
              validator: (_, value) => {
                if (value == null || value.length === 0) return Promise.reject()
                return Promise.resolve()
              },
              message: 'How about we write a recipe before we try saving?',
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div>
              {fields.map(({ key, name, ...field }, index) => (
                <div
                  key={key}
                  style={{ display: 'flex', marginBottom: 8, width: '100%' }}
                >
                  <Sort style={{ paddingBottom: '10px' }}>
                    <div
                      onClick={() => move(index, index - 1)}
                      aria-hidden="true"
                    >
                      <Icon icon="solar:list-arrow-up-minimalistic-line-duotone" />
                    </div>
                    <div
                      onClick={() => move(index, index + 1)}
                      aria-hidden="true"
                      style={{ alignItems: 'end' }}
                    >
                      {' '}
                      <Icon icon="solar:list-arrow-down-minimalistic-line-duotone" />
                    </div>
                  </Sort>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <div>
                      <Form.Item
                        {...field}
                        name={name}
                        validateDebounce={500}
                        rules={[
                          {
                            required: true,
                            message: 'Empty step? Come on, pay attention!',
                          },
                          {
                            min: 2,
                            message: 'Some short step hu',
                          },
                          { max: 6000, message: "No that's too long, come on" },
                          {
                            whitespace: true,
                            message: 'Please use letters?',
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={5}
                          style={{
                            padding: '15px 45px 15px 15px',
                            minHeight: '50px',
                          }}
                        />
                      </Form.Item>

                      <Button
                        size="small"
                        type="primary"
                        onClick={() => remove(name)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '10px',
                        }}
                      >
                        <Icon
                          className="minus"
                          icon="mynaui:minus"
                          height="9px"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <Button
                size="large"
                onClick={() => add()}
                style={{ width: '100%' }}
              >
                Add step
              </Button>
              <Form.ErrorList errors={errors} />
            </div>
          )}
        </Form.List>

        <Label style={{ margin: '30px 0 8px 0', display: 'block' }}>
          Notes{' '}
          <Optional
            style={{
              fontFamily: 'Vollkorn',
              fontSize: '0.9em',
              fontWeight: 400,
            }}
          >
            (optional)
          </Optional>
        </Label>

        <Form.List name="notes">
          {(fields, { add, remove, move }, { errors }) => (
            <div>
              {fields.map(({ key, name, ...field }, index) => (
                <div
                  key={key}
                  style={{ display: 'flex', marginBottom: 8, width: '100%' }}
                >
                  <Sort style={{ paddingBottom: '10px' }}>
                    <div
                      onClick={() => move(index, index - 1)}
                      aria-hidden="true"
                    >
                      <Icon icon="solar:list-arrow-up-minimalistic-line-duotone" />
                    </div>
                    <div
                      onClick={() => move(index, index + 1)}
                      aria-hidden="true"
                      style={{ alignItems: 'end' }}
                    >
                      {' '}
                      <Icon icon="solar:list-arrow-down-minimalistic-line-duotone" />
                    </div>
                  </Sort>
                  <div style={{ width: '100%', position: 'relative' }}>
                    <div>
                      <Form.Item
                        {...field}
                        name={name}
                        validateDebounce={500}
                        rules={[
                          {
                            required: true,
                            message:
                              "Empty note? Why leave it at all? They're optional you know...",
                          },
                          {
                            min: 2,
                            message: 'Some short note hu',
                          },
                          { max: 6000, message: "No that's too long, come on" },
                          {
                            whitespace: true,
                            message: 'Please use letters?',
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={5}
                          style={{
                            padding: '15px 45px 15px 15px',
                            minHeight: '50px',
                          }}
                        />
                      </Form.Item>

                      <Button
                        size="small"
                        type="primary"
                        onClick={() => remove(name)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '10px',
                        }}
                      >
                        <Icon
                          className="minus"
                          icon="mynaui:minus"
                          height="9px"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <Button
                size="large"
                onClick={() => add()}
                style={{ width: '100%' }}
              >
                Add note
              </Button>
              <Form.ErrorList errors={errors} />
            </div>
          )}
        </Form.List>

        {mutationError != null && loadingError != null ? (
          <Alert
            message="I told you to move it along!"
            description={
              <p ref={mutationErrorRef}>
                It ain&apos;t working right now, don&apos;t insist!
              </p>
            }
            type="error"
            showIcon
            icon={<Icon icon="solar:confounded-square-linear" />}
            style={{
              marginTop: '20px',
              marginBottom: '10px',
              textAlign: 'left',
            }}
          />
        ) : (
          mutationError != null && (
            <Alert
              message="OUCH!"
              description={
                <p ref={mutationErrorRef}>
                  I think you&apos;re trying to submit something weird and the
                  server is telling you to get recked, but it could be something
                  else is wrong that&apos;s not entirely your fault...{' '}
                  <i>Maybe</i>. I find this unlikely. My bet is on you fucking
                  up.
                </p>
              }
              type="error"
              showIcon
              icon={<Icon icon="solar:confounded-square-linear" />}
              style={{
                marginTop: '20px',
                marginBottom: '10px',
                textAlign: 'left',
              }}
            />
          )
        )}

        <Actions>
          <Button
            type="text"
            size="large"
            onClick={onCancel}
            style={{ padding: '7px 7px' }}
          >
            <Icon icon="mynaui:x-square" height={24} />
          </Button>

          <Button
            type="primary"
            size="large"
            style={{ padding: '6px 7px' }}
            onClick={async () => {
              try {
                const values = await form.validateFields()

                if (values.tags == null) values.tags = []

                values.tags = values.tags.map((tag: string) => tag.trim())

                await onSave({
                  ...values,
                  id: recipe?.id,
                  author: user.username,
                })
              } catch (e) {
                const field = R.pathOr(undefined, ['errorFields', 0, 'name'], e)
                if (field)
                  form.scrollToField(field, {
                    scrollMode: 'if-needed',
                    block: 'center',
                    inline: 'center',
                    behavior: 'smooth',
                  })
              }
            }}
          >
            <Icon icon="basil:book-check-outline" height={24} />
          </Button>

          {onDelete && (
            <Button
              type="text"
              size="large"
              onClick={confirm}
              style={{ padding: '7px 7px' }}
            >
              <Icon icon="mynaui:trash" height={24} />
            </Button>
          )}
          {contextHolder}
        </Actions>
      </Form>
    </Wrapper>
  )
}
