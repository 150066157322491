import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Empty } from '../assets/empty.svg'

const Wrapper = styled.div`
  svg {
    max-width: 180px;
    width: 100%;
    margin-top: 125px;
    margin-bottom: 20px;
  }

  h1 {
    font-family: 'Averia Serif Libre';
    font-size: 2em;
    margin-top: 0px;
    font-weight: 700;
  }

  button {
    margin-top: 20px;
  }
`

export const NotFound = ({ recipe = true }: { recipe?: boolean }) => (
  <Wrapper>
    <Empty />

    <h1>You&apos;re lost!</h1>

    {recipe ? (
      <p>
        The recipe you&apos;re looking for doesn&apos;t exist. Did you delete it
        perhaps? Don&apos;t try again, it&apos;s not coming back.
      </p>
    ) : (
      <p>
        That link you have is no good. It definitely didn&apos;t come from here.
        Somebody&apos;s pulling your leg!
      </p>
    )}
    <Link to="/">
      <Button size="large">Go home</Button>
    </Link>
  </Wrapper>
)
