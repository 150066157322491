import { Api } from './api'
import { MockApi } from './mock'

const API_URL = process.env.REACT_APP_API_URL

if (API_URL == null)
  console.warn('`REACT_APP_API_URL` was not set, using mock API')

const api = API_URL == null ? new MockApi() : new Api(API_URL)

export { api }
