import { Alert, Button, Divider, Form, Input } from 'antd'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { useForm } from 'antd/es/form/Form'
import { Icon } from '@iconify/react'
import { useSnapshot } from 'valtio'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { ReactComponent as Stew } from '../assets/stew.svg'
import { login, user as userState } from '../state'

const Wrapper = styled.div`
  .ant-input-suffix {
    cursor: pointer;
  }

  .ant-divider {
    border-color: #080c11;
    opacity: 0.2;
  }

  .ant-form-item-explain-error {
    text-align: left;
    margin-bottom: 10px;
  }

  .iconify {
    path,
    circle {
      stroke-width: 2px;
    }
  }

  button .iconify {
    margin-bottom: -4px;
  }
`

const Card = styled.div`
  background-color: #f1f4f3;
  border-radius: 15px;
  padding: 50px;
  margin: 60px 0;
`

const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 11px !important;
`

export const Login = () => {
  const [form] = useForm()
  const { loading, loginError: error, loginRedirect } = useSnapshot(userState)
  const navigate = useNavigate()

  const errorRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (error != null)
      errorRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
  }, [error])

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '60px',
        }}
      >
        <Logo style={{ width: '100px' }} />
        <Stew style={{ maxWidth: '180px' }} />
      </div>

      <Card>
        <Form
          disabled={loading}
          form={form}
          layout="vertical"
          requiredMark={(label) => <>{label}</>}
        >
          <Form.Item
            help={false}
            name="username"
            label={<Label>Username</Label>}
            required
            rules={[{ required: true }]}
          >
            <Input
              prefix={
                <Icon icon="solar:user-linear" style={{ opacity: 0.5 }} />
              }
              size="large"
            />
          </Form.Item>

          <Form.Item
            help={false}
            name="password"
            label={<Label>Password</Label>}
            required
            rules={[{ required: true }]}
          >
            <Input.Password
              size="large"
              type="password"
              prefix={
                <Icon
                  icon="solar:lock-password-linear"
                  style={{ opacity: 0.5 }}
                />
              }
              iconRender={(visible) =>
                visible ? (
                  <Icon icon="solar:eye-linear" />
                ) : (
                  <Icon icon="solar:eye-closed-linear" />
                )
              }
            />
          </Form.Item>

          <Divider />

          <Button
            onClick={async () => {
              try {
                const values = await form.validateFields()
                await login(values)
                navigate(loginRedirect || '/')
              } catch {
                // do nothing
              }
            }}
            size="large"
            type="primary"
            style={{ width: '100%' }}
            loading={loading}
            icon={<Icon icon="solar:login-2-linear" height={16} />}
          >
            Login
          </Button>
        </Form>
      </Card>

      {error != null && (
        <Alert
          message="Denied!"
          description={
            <p ref={errorRef}>
              You&apos;re sure you have the right password? Are you trying to
              hack this account? If so, you&apos;re not doing a good job so
              far...
            </p>
          }
          type="error"
          showIcon
          icon={<Icon icon="solar:confounded-square-linear" />}
          style={{
            marginTop: '-40px',
            marginBottom: '40px',
            textAlign: 'left',
          }}
        />
      )}
    </Wrapper>
  )
}
