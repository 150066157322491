import React from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import styled from 'styled-components'
import { IconButton } from './IconButton'

const Wrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px 0 20px 0;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  background: white;
  z-index: 1000;
  border-bottom: 1px solid #f0f0f0;

  button:hover {
    background: transparent !important;
  }
`

export const Nav = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Wrapper>
      <IconButton
        disabled={location.key === 'default'}
        icon="mynaui:arrow-left"
        onClick={() => navigate(-1)}
        style={{ paddingLeft: '0px' }}
      />

      {location.pathname !== '/' ? (
        <Link to="/">
          <IconButton icon="mynaui:home-smile" />
        </Link>
      ) : (
        <div style={{ width: '45px' }} />
      )}

      <div style={{ width: '37.5px' }} />
    </Wrapper>
  )
}
