import * as R from 'ramda'

export type User = {
  username: string
}

export type Recipe = {
  id: string
  author: string
  title: string
  tags: string[]
  prepTime?: number
  cookTime?: number
  restingTime?: number
  serveQuantity?: number
  serveUnit?: string
  ingredients: Ingredient[]
  steps: string[]
  notes: string[]
}

export type Ingredient = {
  name: string
  quantity?: number
  unit?: string
}

export const units = [
  'l',
  'cl',
  'ml',
  'g',
  'kg',
  'tbsp.',
  'tsp.',
  'cup',
  'pinch',
] as const

export type ErrorCode = 'unexpected' | 'not-found' | 'unauthorized'

export class AppError extends Error {
  code: ErrorCode = 'unexpected'

  constructor(e: { code: ErrorCode; message?: string } | Error | string) {
    super(AppError.getMessage(e))

    if (R.has('code', e)) this.code = e.code
  }

  static getMessage(e: { code: ErrorCode; message?: string } | Error | string) {
    if (R.is(Error, e)) return e.message

    if (R.is(String, e)) return e

    return undefined
  }

  static isNotFound(e: unknown) {
    return R.is(AppError, e) && e.code === 'not-found'
  }
}

export const formatTime = (totalMin: number) => {
  if (totalMin === 0) return null

  if (totalMin < 60) return `${totalMin} min`

  const hours = Math.floor(totalMin / 60)
  const minutes = totalMin % 60

  let formatted = `${hours} h`

  if (minutes !== 0) formatted += ` ${minutes} min`

  return formatted
}
