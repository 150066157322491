import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert } from 'antd'
import { Icon } from '@iconify/react'

import {
  createRecipe,
  deleteRecipe,
  updateRecipe,
  useRecipe,
  useTags,
} from '../state'
import * as data from '../data'
import { RecipeDraft } from '../components/RecipeDraft'
import { NotFound } from '../components/NotFound'
import { Loading } from '../components/Loading'
import { Nav } from '../components/Nav'

export const RecipeEditPage = ({ user }: { user?: data.User }) => {
  const {
    recipe,
    mutating,
    loading: recipeLoading,
    loadingError,
    mutationError,
  } = useRecipe()
  const { tags, loading: tagsLoading } = useTags()
  const navigate = useNavigate()

  useEffect(() => {
    if (recipe) {
      document.title = `${recipe.title.toLocaleLowerCase()}`
    } else {
      document.title = "pot's luck"
    }
  }, [recipe, recipeLoading, loadingError])

  if (data.AppError.isNotFound(loadingError)) return <NotFound />

  if (recipeLoading || user == null) return <Loading />

  if (loadingError?.message === 'Unauthorized') navigate('/login')

  const unauthorized = user && recipe && user.username !== recipe.author

  if (unauthorized)
    return (
      <>
        <Nav />
        <Alert
          message="Busted!"
          description={
            <p>
              This is not yours, you can&apos;t edit it! Your IP address has
              been logged and you will be reported to the authorities.
            </p>
          }
          type="error"
          showIcon
          icon={<Icon icon="mdi:robot-angry-outline" />}
          style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'left' }}
        />
      </>
    )

  return (
    <RecipeDraft
      user={user}
      recipe={recipe as data.Recipe | undefined}
      availableTags={tags as string[]}
      onCancel={() => navigate(-1)}
      loading={recipeLoading || mutating}
      tagsLoading={tagsLoading}
      loadingError={loadingError}
      mutationError={mutationError}
      onDelete={
        recipe == null
          ? undefined
          : async () => {
              const state = await deleteRecipe(recipe.id)
              if (state.mutationError == null) navigate('/')
            }
      }
      onSave={async (saved: data.Recipe) => {
        if (recipe == null) {
          const state = await createRecipe(saved)
          if (state.mutationError == null && state.recipe != null)
            navigate(`/${state.recipe.id}`)
          return
        }

        const state = await updateRecipe(saved)
        if (state.recipe != null && state.mutationError == null)
          navigate(`/${recipe.id}`)
      }}
    />
  )
}
