import { proxy, useSnapshot } from 'valtio'
import { useParams, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Recipe, User } from '../data'
import { api } from '../api'

export const user = proxy<{
  user?: User
  loading: boolean
  error?: Error
  loginError?: Error
  loginRedirect?: string
}>({
  user: undefined,
  loading: false,
  error: undefined,
  loginError: undefined,
  loginRedirect: undefined,
})

export const recipe = proxy<{
  recipe?: Recipe
  loading: boolean
  mutating: boolean
  loadingError?: Error
  mutationError?: Error
}>({
  recipe: undefined,
  loading: false,
  mutating: false,
  loadingError: undefined,
  mutationError: undefined,
})

export const resetRecipe = () => {
  recipe.recipe = undefined
  recipe.loading = false
  recipe.mutating = false
  recipe.loadingError = undefined
  recipe.mutationError = undefined
}

export const search = proxy<{
  search?: string
  found?: Recipe[]
  loading: boolean
  error?: Error
}>({
  search: undefined,
  found: undefined,
  loading: false,
  error: undefined,
})

export const tags = proxy<{
  tags: string[]
  loading: boolean
  error?: Error
}>({
  tags: [],
  loading: false,
  error: undefined,
})

export const useUser = () => {
  const snap = useSnapshot(user)

  useEffect(() => {
    ;(async () => {
      if (snap.user == null && snap.error == null && !snap.loading) {
        user.loading = true
        try {
          user.user = await api.getCurrentUser()
        } catch (error) {
          user.error = error as Error
        }
        user.loading = false
      }
    })()
  }, [snap])

  return snap
}

export const login = async ({
  username,
  password,
}: {
  username: string
  password: string
}) => {
  try {
    user.loading = true
    user.error = undefined
    user.loginError = undefined
    user.user = await api.login({ username, password })
    resetRecipe()
    search.error = undefined
  } catch (error) {
    user.loginError = error as Error
    user.user = undefined
  }
  user.loading = false
  return user.user
}

export const setSearch = (s?: string) => {
  search.search = s
  if (s == null || s === '') {
    search.found = undefined
  }
}

export const searchRecipes = async (s?: string) => {
  search.search = s

  if (s == null || s === '') {
    search.found = undefined
    return search.found
  }

  search.loading = true
  search.error = undefined

  try {
    search.found = (await api.searchRecipes(s)).map((r) => {
      r.tags.sort()
      return r
    })
  } catch (error) {
    search.error = error as Error
  }

  search.loading = false

  return search.found
}

export const updateRecipe = async (r: Recipe) => {
  recipe.mutating = true
  recipe.mutationError = undefined

  try {
    recipe.recipe = await api.updateRecipe(r)
  } catch (error) {
    recipe.mutationError = error as Error
  }

  recipe.mutating = false

  return recipe
}

export const createRecipe = async (r: Omit<Recipe, 'id'>) => {
  recipe.mutating = true
  recipe.mutationError = undefined

  try {
    recipe.recipe = await api.createRecipe(r)
  } catch (error) {
    recipe.mutationError = error as Error
  }

  recipe.mutating = false

  return recipe
}

export const deleteRecipe = async (id: string) => {
  recipe.mutating = false

  try {
    recipe.mutating = true
    recipe.mutationError = undefined

    await api.deleteRecipe(id)
  } catch (error) {
    recipe.mutationError = error as Error
  }

  recipe.mutating = false

  return recipe
}

export const useRecipe = () => {
  const snap = useSnapshot(recipe)
  const { id } = useParams()

  useEffect(() => {
    ;(async () => {
      if (
        snap.loading ||
        (snap.loadingError && snap.recipe != null && snap.recipe.id === id)
      )
        return

      if (id == null) {
        recipe.loading = false
        recipe.recipe = undefined
        recipe.loadingError = undefined
        return
      }

      recipe.loading = true

      try {
        if (
          (snap.recipe == null || snap.recipe.id !== id) &&
          snap.loadingError == null
        ) {
          recipe.recipe = await api.getRecipeById(id)
          recipe.recipe.tags.sort()
          recipe.loadingError = undefined
        }
      } catch (error) {
        recipe.loadingError = error as Error
      }

      recipe.loading = false
    })()
  }, [snap.recipe, snap.loading, snap.loadingError, id])

  return snap
}

export const useSearch = () => {
  const snap = useSnapshot(search)
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    const s = params.get('s')

    search.search = s || undefined

    if (search.search == null && s != null) {
      params.delete('s')
      setParams(params)
    }
  }, [params, setParams])

  return snap
}

export const getAllTags = async () => {
  tags.loading = true
  tags.error = undefined

  try {
    tags.tags = await api.getAllTags()
  } catch (error) {
    tags.error = error as Error
  }

  tags.loading = false

  return tags.tags
}

export const useTags = () => {
  const snap = useSnapshot(tags)

  useEffect(() => {
    getAllTags()
  }, [])

  return snap
}
