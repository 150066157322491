import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import { ConfigProvider } from 'antd'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import theme from './theme'
import { NotFound } from './components/NotFound'
import { Search } from './containers/Search'
import { RecipePage } from './containers/RecipePage'
import { RecipeEditPage } from './containers/RecipeEditPage'
import { ScrollToTop } from './components/Scroll'
import { useUser, user as userState } from './state'
import { Login } from './containers/Login'

const Wrapper = styled.div`
  text-align: center;
  font-family: 'Vollkorn';
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #182642;

  max-width: 500px;
  margin: auto;
  padding: 10px;

  * {
    box-sizing: border-box;
  }

  .iconify {
    path {
      stroke-width: 1;
    }
  }

  a,
  a:hover,
  a:focus,
  a:active {
    color: inherit;
  }

  a,
  a:hover,
  a:focus,
  a:active,
  button {
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  ::-moz-selection {
    background: #ccf0e3;
  }

  ::selection {
    background: #ccf0e3;
  }

  .ant-alert ::-moz-selection {
    background: #ffc7be;
  }

  .ant-alert ::selection {
    background: #ffc7be;
  }

  .ant-alert-icon path {
    stroke-width: 1.5px;
  }

  .ant-alert p:last-child {
    margin-bottom: 0;
  }

  .ant-alert-icon {
    margin-top: -4px;
  }
`

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <Icon
      style={{ fontSize: 20, margin: '20px 0px 0px' }}
      icon="mdi:robot-confused-outline"
    />
    <p
      style={{
        margin: 0,
        marginBottom: '20px',
        fontSize: '12px',
        fontWeight: 500,
      }}
    >
      There&apos;s nothing here...
    </p>
  </div>
)

export const App = () => {
  const { user, error, loading } = useUser()
  const [init, setInit] = useState(true)
  const navigate = useNavigate()
  const loc = useLocation()

  useEffect(() => {
    setInit(false)

    if (error != null || (!init && !loading && user == null)) {
      if (loc.pathname !== '/login') userState.loginRedirect = loc.pathname
      navigate('login')
    } else {
      userState.loginRedirect = undefined
    }
  }, [error, user, loading, init, navigate, loc.pathname])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#080c11',
          colorPrimaryBorder: '#080c11',
          colorPrimaryBorderHover: '#080c11',
          colorPrimaryBg: '#080c11',
          colorPrimaryBgHover: '#080c11',
          colorBgContainerDisabled: '#f1f4f3',
          fontFamily: 'Vollkorn',
          fontSizeLG: 14,
          borderRadiusLG: 15,
          borderRadius: 12,

          screenXS: 0,
          screenXSMin: 0,
          screenXSMax: 400,

          screenSM: 400,
          screenSMMin: 400,

          colorText: '#080c11',
        },
        components: {
          Button: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSizeLG: 12,
            textHoverBg: '#f2f4f5',
            primaryShadow: 'transparent',
          },
          Select: {
            optionSelectedColor: '#080c11',
            optionSelectedFontWeight: 400,
            optionSelectedBg: '#ccf0e3',
            fontFamily: 'Montserrat',
            fontSizeLG: 12,
            multipleItemBg: '#ccf0e3',
            colorText: '#182642',
            colorIcon: '#182642',
            colorIconHover: '#182642',
          },
          Form: {
            marginLG: 10,
            labelFontSize: 10,
            verticalLabelPadding: '0 0 5px 0',
          },
        },
      }}
      renderEmpty={customizeRenderEmpty}
    >
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Routes>
            <Route path="/" element={<Search />} />
            <Route path="/login" element={<Login />} />
            <Route path="/new" element={<RecipeEditPage user={user} />} />
            <Route path="/:id" element={<RecipePage user={user} />} />
            <Route path="/:id/edit" element={<RecipeEditPage user={user} />} />
            <Route path="*" element={<NotFound recipe={false} />} />
          </Routes>
        </Wrapper>
      </ThemeProvider>
    </ConfigProvider>
  )
}
