import { AppError, Recipe, User } from '../data'

export class Api {
  constructor(public url: string) {}

  private async _rpc<T>(method: string, params: any): Promise<T> {
    return fetch(this.url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ method, params }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result != null) return res.result as T
        if (res.error.code === 1) throw new AppError({ code: 'not-found' })
        throw new Error(res.error.message)
      })
  }

  async login({
    username,
    password,
  }: {
    username: string
    password: string
  }): Promise<User> {
    return fetch(`${this.url}/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
      credentials: 'include',
    }).then((res) => res.json())
  }

  async getCurrentUser(): Promise<User> {
    return this._rpc('users.getCurrent', {})
  }

  async searchRecipes(search: string): Promise<Recipe[]> {
    return this._rpc('recipes.search', { search })
  }

  async getRecipeById(id: string): Promise<Recipe> {
    return this._rpc('recipes.get', { id })
  }

  async createRecipe(recipe: Omit<Recipe, 'id'>): Promise<Recipe> {
    return this._rpc('recipes.create', { recipe })
  }

  async updateRecipe(recipe: Recipe): Promise<Recipe> {
    return this._rpc('recipes.update', { recipe })
  }

  async deleteRecipe(id: string) {
    return this._rpc('recipes.delete', { id })
  }

  async getAllTags(): Promise<string[]> {
    return this._rpc('tags.getAll', {})
  }
}
