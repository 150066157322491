import React from 'react'
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const ProgressBar = styled.div`
  height: 2px;
  background-color: #18264213;
  width: 100%;
  overflow: hidden;
`
const animation = keyframes`
  0% {
    transform:  translateX(0) scaleX(0);
  }

  50% {
    transform:  translateX(10%) scaleX(0.9);
  }

  100% {
    transform:  translateX(110%) scaleX(0);
  }
`

const ProgressBarVal = styled.div`
  width: 100%;
  height: 100%;
  background-color: #182642;
  animation: ${animation} 1s infinite cubic-bezier(0.79, 0.14, 0.24, 0.54);
  transform-origin: 0% 50%;
`

export const Loading = () => (
  <Wrapper>
    <ProgressBar>
      <ProgressBarVal />
    </ProgressBar>
  </Wrapper>
)
