import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import Markdown from 'react-markdown'

import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { Tag } from './Tag'
import * as data from '../data'
import { Nav } from './Nav'
import { IconButton } from './IconButton'
import { Crossable } from './Crossable'

const Wrapper = styled.div`
  margin-bottom: 90px;
`

const H1 = styled.h1`
  font-family: 'Averia Serif Libre';
  font-weight: 700;
  font-size: 2em;
  line-height: 1em;
  margin-top: 0px;
`

const Author = styled.div`
  opacity: 0.5;
  font-family: 'Montserrat';
  font-size: 0.9em;
  margin-top: 60px;
  margin-bottom: 0px;

  span {
    font-weight: 700;
  }

  svg {
    height: 1.5em;
    width: 1.5em;
    margin-bottom: -4px;
    margin-right: 5px;
  }
`

const H2 = styled.h2`
  font-family: 'Montserrat';
  margin: 0;
  font-weight: 700;
  font-size: 0.9em;
`

const Tags = styled.div`
  margin-top: 20px;

  span {
    margin-left: 5px;
    margin-top: 5px;
    background-color: #ccf0e3;
    border-radius: 6px;
    padding: 5px 10px;
    font-family: 'Montserrat';
    font-size: 0.7em;
    font-weight: 700;
    color: #182642;
    display: inline-block;
  }
`

const Servings = styled.div`
  margin-bottom: 30px;

  .iconify path {
    stroke-width: 2px !important;
  }
`

const TimeIndications = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;

  p {
    flex-direction: column;
    margin: 0;

    span {
      font-family: 'Montserrat';
      font-size: 0.8em;
      font-weight: 700;
    }
  }
`

const IngredientList = styled.ul`
  padding-left: 20px;
  text-align: left;
  columns: 9em auto;
  margin-bottom: 0;
`

const Ingredient = styled.li`
  margin: 0;
  list-style: circle;

  span {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  span.quantity {
    font-weight: 400;
    font-family: 'Vollkorn';
    font-size: 16px;
    opacity: 0.6;
    white-space: nowrap;
  }

  span.name {
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 13px;
    margin-right: 7px;
  }
`

const Instructions = styled.ul`
  padding-left: 20px;
`

const Step = styled.li`
  text-align: left;
  font-weight: 400;
  font-size: 1em;
  list-style: decimal;
  margin-bottom: 20px;

  a {
    text-decoration: underline !important;
    text-decoration-thickness: 1.5px !important;

    &:hover {
      text-decoration: none !important;
      opacity: 0.7;
    }
  }
`

const Notes = styled.ul`
  padding-left: 20px;
`

const Note = styled.li`
  opacity: 0.8;
  text-align: left;
  font-weight: 400;
  font-size: 0.9em;
  list-style: none;
  margin-bottom: 20px;

  p {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline !important;
    text-decoration-thickness: 1.5px !important;

    &:hover {
      text-decoration: none !important;
      opacity: 0.7;
    }
  }
`

const Card = styled.div`
  background-color: #f1f4f3;
  border-radius: 15px;
  padding: 30px;
  margin: 60px 0;
`

const Actions = styled.div`
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  path {
  }
`

const formatQuantity = (qty: number): string => {
  if (!qty) return ''

  // eslint-disable-next-line
  return String(+(Math.round(qty + "e+2" as any)  + "e-2"))
}

export const Recipe = ({
  recipe,
  user,
}: {
  recipe: data.Recipe
  user: data.User
}) => {
  const [adjustedServings, setAdjustedServings] = useState(
    recipe.serveQuantity || 1
  )

  return (
    <Wrapper>
      <Nav />

      <Author>
        <Icon icon="solar:chef-hat-minimalistic-linear" />
        <span>{recipe.author}</span>&apos;s
      </Author>
      <H1>{recipe.title}</H1>

      <Tags>
        {recipe.tags.map((value) => (
          <Tag key={value} value={value} />
        ))}
      </Tags>

      <TimeIndications>
        {recipe.prepTime && (
          <p>
            <span>Prep</span>: {data.formatTime(recipe.prepTime)}
          </p>
        )}
        {recipe.cookTime && (
          <p>
            <span>Cook</span>: {data.formatTime(recipe.cookTime)}
          </p>
        )}
        {recipe.restingTime && (
          <p>
            <span>Resting</span>: {data.formatTime(recipe.restingTime)}
          </p>
        )}
      </TimeIndications>

      <Card>
        {recipe.serveQuantity && (
          <Servings>
            <Button
              size="small"
              type="primary"
              style={{ padding: '0 6px' }}
              onClick={() => setAdjustedServings(adjustedServings - 1 || 1)}
            >
              <Icon icon="mynaui:minus" height="11px" />
            </Button>
            <span style={{ margin: '0 10px' }}>
              {`${adjustedServings} ${recipe.serveUnit ?? 'servings'}`}
            </span>
            <Button
              size="small"
              type="primary"
              style={{ padding: '0 6px' }}
              onClick={() => setAdjustedServings(adjustedServings + 1)}
            >
              <Icon icon="mynaui:plus" height="11px" />
            </Button>
          </Servings>
        )}

        <H2>Ingredients</H2>

        <IngredientList>
          {recipe.ingredients.map((ing) => (
            <Ingredient key={ing.name + ing.quantity + ing.unit}>
              <Crossable>
                <span className="name">{ing.name}</span>

                <span className="quantity">
                  {formatQuantity(
                    (ing.quantity || 0) /
                      ((recipe.serveQuantity || 1) / adjustedServings)
                  )}{' '}
                  {ing.unit ? ` ${ing.unit}` : ''}
                </span>
              </Crossable>
            </Ingredient>
          ))}
        </IngredientList>
      </Card>

      <H2>Instructions</H2>

      <Instructions>
        {recipe.steps.map((step) => (
          <Step key={step}>
            <Crossable>
              {' '}
              <Markdown
                components={{
                  a: (props: { href?: string; children?: any }) => (
                    <Link to={props.href || ''}>{props.children}</Link>
                  ),
                }}
                allowedElements={['p', 'ul', 'ol', 'li', 'em', 'strong', 'a']}
              >
                {step}
              </Markdown>
            </Crossable>
          </Step>
        ))}
      </Instructions>

      {(recipe.notes || []).length > 0 && (
        <div
          style={{
            marginTop: '60px',
            borderTop: '1px solid #e6e6e6',
            paddingTop: '40px',
          }}
        >
          <H2>Notes</H2>

          <Notes>
            {recipe.notes.map((note) => (
              <Note key={note}>
                <Markdown
                  allowedElements={['p', 'ul', 'ol', 'li', 'em', 'strong', 'a']}
                  components={{
                    a: (props: { href?: string; children?: any }) => (
                      <Link to={props.href || ''}>{props.children}</Link>
                    ),
                  }}
                >
                  {note}
                </Markdown>
              </Note>
            ))}
          </Notes>
        </div>
      )}

      {user.username === recipe.author && (
        <>
          <div style={{ height: '1px', marginTop: '40px' }} />

          <Actions>
            <Link to={`/${recipe.id}/edit`}>
              <IconButton type="primary" icon="mynaui:edit-one" />
            </Link>
          </Actions>
        </>
      )}
    </Wrapper>
  )
}
