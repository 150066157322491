import React, { useEffect } from 'react'
import { Alert } from 'antd'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'

import { useRecipe } from '../state'
import { Recipe } from '../components/Recipe'
import * as data from '../data'
import { Loading } from '../components/Loading'
import { Nav } from '../components/Nav'
import { NotFound } from '../components/NotFound'

export const RecipePage = ({ user }: { user?: data.User }) => {
  const { recipe, loading, loadingError } = useRecipe()
  const navigate = useNavigate()

  useEffect(() => {
    if (recipe) document.title = `${recipe.title.toLocaleLowerCase()}`
  }, [recipe])

  if (data.AppError.isNotFound(loadingError)) return <NotFound />

  if (loading || (recipe == null && loadingError == null) || user == null)
    return <Loading />

  if (loadingError?.message === 'Unauthorized') navigate('/login')

  if (loadingError != null)
    return (
      <>
        <Nav />

        <Alert
          message="Uh-oh"
          description={
            <p>
              So I know you were expecting a recipe here, I&apos;m sure it was
              very nice, but something went wrong... Either your internet is
              down, or... That&apos;s it, that&apos;s the only thing that can be
              wrong, because it&apos;s certainly <i>not</i> a problem with the
              professionally written code of this website. Try again when you
              get your internet back.
            </p>
          }
          type="error"
          showIcon
          icon={<Icon icon="solar:confounded-square-linear" />}
          style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'left' }}
        />
      </>
    )

  return <Recipe recipe={recipe as data.Recipe} user={user} />
}
