import React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 2.5px 5px 2.5px 0;
  background-color: #ccf0e3;
  border-radius: 6px;
  padding: 5px 10px;
  font-family: 'Montserrat';
  font-size: 0.7em;
  font-weight: 700;
  color: #182642;
  display: inline-block;
`

export const Tag = ({ value }: { value: string }) => <Wrapper>{value}</Wrapper>
